import SEO from "../components/SEO";
import React, { FC } from "react";
import Layout from "../components/Layout";
import { getCurrentLanguage } from "../i18n";
import "./404.scss";
import { Game404 } from "../components/Game404";
import SectionHeader from "../components/SectionHeader";
import { t } from "i18next";
import Accordion from "../components/Accordion";
import { AccordionGroup } from "../components/AccordionGroup/AccordionGroup";

const NotFound: FC = (_) => {
  const title = t("404.title");
  const lang = getCurrentLanguage();

  return (
    <Layout hideMenu>
      <SEO title={title} description={""} lang={lang} slug={`/${lang}/404`} />
      <div className="page-404 custom-scrollbar">
        <SectionHeader />
        <div className="page-404__content">
          <h1 className="page-404__title">{title}</h1>
          <Game404 />
        </div>
        <div className="page-404__footer">
          <AccordionGroup>
            <Accordion
              isNormal
              label={`© Copyright ${new Date().getFullYear()} Patrick Stillhart`}
            >
              <span>
                Permission is hereby granted, free of charge, to any person
                obtaining a copy of this software and associated documentation
                files (the "Software"), to deal in the Software without
                restriction, including without limitation the rights to use,
                copy, modify, merge, publish, distribute, sublicense, and/or
                sell copies of the Software, and to permit persons to whom the
                Software is furnished to do so, subject to the following
                conditions:
              </span>
              <span>
                The above copyright notice and this permission notice shall be
                included in all copies or substantial portions of the Software.
              </span>
              <span>
                THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
                EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON
                INFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS
                BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN
                AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF
                OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
                IN THE SOFTWARE.
              </span>
            </Accordion>
          </AccordionGroup>
        </div>
      </div>
    </Layout>
  );
};
export default NotFound;
