import React, { FC, ReactElement, useEffect, useState } from "react"

export interface AccordionGroupContextProps {
  state?: { [key: number]: boolean };
  indexClicked?: { [key: number]: boolean };
  setIndexClicked: (indexClicked: { [key: number]: boolean }) => void;
}

const AccordionGroupContext = React.createContext<AccordionGroupContextProps>({
  state: {},
  indexClicked: {},
  setIndexClicked: () => {},
});

export const AccordionGroup: FC = ({ children }) => {
  const [state, setState] = useState<{ [key: number]: boolean }>({});
  const [indexClicked, setIndexClicked] = useState<{ [key: number]: boolean }>();

  const value: AccordionGroupContextProps = {
    state,
    indexClicked,
    setIndexClicked,
  };

  useEffect(() => {
    const defaultState: { [key: number]: boolean } = {};
    React.Children.forEach(children, (_, index) => {
      defaultState[index] = false;
    });
    
    setState(defaultState);

    return () => {
      setState({});
    };
  }, []);

  useEffect(() => {
    if (!indexClicked) {
      return;
    }
    
    const newState = Object.keys(state).reduce((acc, current) => {
      return { ...acc, [current]: false };
    }, {});
    
    setState({ ...newState, ...indexClicked });
  }, [indexClicked]);

  return (
    <AccordionGroupContext.Provider value={value}>
      {
        React.Children.map(children, (child, index) => {
          const props = {
            index,
            opened:  !!state[index],
            disabled:   !state[index] && Object.values(state).some(el => el !== undefined && !!el),
          };
          return React.cloneElement(child as ReactElement, props);
        })
      }
    </AccordionGroupContext.Provider>
  )
}
export default AccordionGroupContext
