import "./Accordion.scss"

import React, { FC, useContext, useEffect, useLayoutEffect, useRef } from "react"

import AccordionGroupContext from "../AccordionGroup"

export interface AccordionProps {
  index?: number;
  label: string;
  children: any;
  opened?: boolean;
  disabled?: boolean;
  isNormal?: boolean;
}

const Accordion: FC<AccordionProps> = ({ index, label, children, opened, disabled, isNormal }) => {
  const { state, setIndexClicked } = useContext(AccordionGroupContext);

  const accordionClickHandler = () => {
    setIndexClicked({ [index]: !state[index] });
  };

  const accordion = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (!accordion.current) {
      return;
    }
    accordion.current.style.setProperty('--height', `${accordion.current.scrollHeight}px`);
  }, [children]);

  useEffect(() => {
    if (!accordion.current) {
      return;
    }
    
    accordion.current.style.setProperty('height', opened ? 'var(--height)' : '0');
  }, [opened]);

  return (
    <div
      className={`accordion ${opened ? 'accordion--open' : ''} ${disabled ? 'accordion--disabled' : ''}`}
    >
      <div className="accordion__header cursor-click" data-cursor-text-color="#fff" onClick={accordionClickHandler}>
        {!isNormal ? 
          <h2>{label}</h2>
          :
          <p>{label}</p>  
        }
      </div>
      <div
        ref={accordion}
        className="accordion__body"
      >
        <div className="accordion__content">
          {children}
        </div>
      </div>
    </div>
  ) 
}
export default Accordion
